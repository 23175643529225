.wallet-adapter-button {
  background-color: black !important;
  color: white !important;
  font-weight: bold !important;
  height: 44px !important;
  border-radius: 0.5rem !important;
  border: 1px solid #374151 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.5rem !important;
  transition: background-color 0.2s !important;
  padding: 0px;
}

.wallet-adapter-modal-list button {
  border-radius: 0px !important;
  padding: 10px 20px !important;
  margin: 5px 0px !important;
  border: 2px solid #BA6C48 !important;
  background: #422301 !important;
}


.wallet-adapter-button-start-icon {
  position: absolute;
  left: 15px;
}

.wallet-adapter-modal-list .wallet-adapter-button-start-icon {
  position: relative;
  left: unset;
}

.wallet-adapter-button:hover {
  background-color: #2d1701 !important;
}

.wallet-adapter-button-trigger {
  background-color: black !important;
}

.wallet-adapter-modal-wrapper {
  border: 5px solid #BA6C48;
  box-shadow: inset 4px 0px 0px #3D2021, inset 0px 4px 0px #3D2021, inset -2px 0px 0px #3D2021, inset 0px -2px 0px #3D2021;
  background: #5D2C28;
  border-radius: 0px;
  padding: 30px;
}